// Mixins
@import "brakesshop/base/mixins/media-queries";

// Vendor libs
@import 'brakesshop/variables';
//@import 'vendor/normalize/lib'; // Normalize.css //Disabled, duplicate from lib-magento-reset?
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'brakesshop/variables';
@import 'brakesshop/base/variables';

@import "styles-base";
